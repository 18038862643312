<template>
    <div>
        <div class="setting_content">
            <div class="setting_title" style="border-bottom: 1px solid #EFEFEF;">
                谷物杂粮设置
            </div>
            <div class="setting_content_content">
                <!-- 基本信息 -->
                <BasicInformation></BasicInformation>
                <div class="line"></div>
                <!-- 摄像头 -->
                <CameraList></CameraList>
                <div class="line"></div>
                <!-- 气象传感器 -->
                <IotMeteorologicalList></IotMeteorologicalList>
                <div class="line"></div>
                <!-- 土壤传感器 -->
                <IotSoilList></IotSoilList>
                <!-- 地图列表 -->
                <div class="line"></div>
                <MapTableLsit></MapTableLsit>
                <div class="line"></div>
                <!-- 全区谷物杂粮主要播种品种面积与产量统计分析 -->
                <TowGoodsTableLsit></TowGoodsTableLsit>
                <div class="line"></div>
                <!-- 各乡镇谷物杂粮面积和产量统计 -->
                <Vegetable></Vegetable>
                <div class="line"></div>
                <!-- 全区谷物杂粮主要播种品种价格区间 -->
                <VegetableAre></VegetableAre>
            </div>
        </div>
    </div>
</template>

<script>
import BasicInformation from './components/dataScreen16_gwzl/basicInformation.vue'
import CameraList from './components/dataScreen16_gwzl/cameraList.vue'
import IotMeteorologicalList from './components/dataScreen16_gwzl/iotMeteorologicalList.vue'
import IotSoilList from './components/dataScreen16_gwzl/iotSoilList.vue'
import Vegetable from './components/dataScreen16_gwzl/vegetable.vue'
import VegetableAre from './components/dataScreen16_gwzl/vegetablearea.vue'
import MapTableLsit from './components/dataScreen16_gwzl/mapTableLsit.vue'
import TowGoodsTableLsit from './components/dataScreen16_gwzl/towGoodsTableLsit.vue'

export default {
    name: '',
    data(){
        return{

        }
    },
    components: {
        IotMeteorologicalList,
        IotSoilList,
        CameraList,
        BasicInformation,
        Vegetable,
        VegetableAre,
        MapTableLsit,
        TowGoodsTableLsit
    },
    mounted(){

    },
    methods:{

    }
}
</script>

<style scoped>
.menu_title {
    width: 100%;
    height: 146px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .setting_content {
      /* padding: 24px; */
      background: #fff;
  }
  .setting_title {
      padding: 24px;
      padding-bottom: 6px;
  }
  .setting_content_content {
      padding: 24px;
  }
  .bootom {
      width: calc(100% - 254px);
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 230px;
      background: #fff;
      margin-left: -24px;
      text-align: center;
      line-height: 44px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .disease-box {
    position: relative;

    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px;
  }
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 50;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }

  .avatar-uploader {
    width: 150px;
    height: 162px;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 150px;
    height: 162px;
  }
</style>