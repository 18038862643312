import { render, staticRenderFns } from "./towGoodsTableLsit.vue?vue&type=template&id=468ee60c&scoped=true&"
import script from "./towGoodsTableLsit.vue?vue&type=script&lang=js&"
export * from "./towGoodsTableLsit.vue?vue&type=script&lang=js&"
import style0 from "./towGoodsTableLsit.vue?vue&type=style&index=0&id=468ee60c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468ee60c",
  null
  
)

export default component.exports