<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="180px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="播种总面积（亩）">
                        <el-input v-model="form.totalSownArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="谷物面积（亩）">
                        <el-input v-model="form.grainArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="谷物总产量（吨）">
                        <el-input v-model="form.beansArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="豆类面积（亩）">
                        <el-input v-model="form.totalSowingOutput"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="豆类总产量（吨）">
                        <el-input v-model="form.demonstrationBaseArea"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="ta_c">
                <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
            </el-row>
        </el-form>  
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            form: {},
        }
    },
    
    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.datascreen18QueryById({id: 1}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        onSaveInfo() {
            this.qa.datascreen18DoInsert(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
    .line {
        width: 100%;
        height: 1px;
        margin: 30px 0;
        background: #ccc;
    }
</style>